<template>
  <div>
    <ClientSidepanel
      @funnelRemoved="getListings()"
      :customer-data="offersCustomerId"
      :only-panel="true"
      v-if="clientSidepanelStatus"
    />
    <ListingSidepanel
      v-if="listingSidepanelStatus"
      :listing-id="offersListingId"
      :key="contentUpdater"
      :dont-nav-to-previous-route="fromListingsAdd"
      :previous-listing-page="lastVisitedListingPage"
      :selected-view="activeTab"
      @statusChanged="updateListing"
      @updateStatuses="updateFromSidepanel"
    />
    <Header
      id="listing-header"
      :filterConditions="filterConditions"
      :saved-view-id="savedViewId"
      :board-leads-updater="boardLeadsUpdater"
      :selected-view="activeTab"
      @selectedBroker="selectBrokerId"
      @activeTab="changeTab"
      @filtersOpen="filtersOpen"
      ref="header"
    />

    <div
      v-if="selectedListingsList.length > 0"
      id="selected-listing-header"
      class="selected-listings-header"
      v-bind:class="{ 'slide-animation-open': contactBarOpen }"
    >
      <div class="selected-listings-display">
        <p
          style="
            font-size: 10px;
            line-height: 16px;
            font-weight: 600;
            font-family: Inter, serif;
          "
        >
          {{ selectedListingsList.length + " " + $t("Selected") }}
        </p>
        <img
          style="cursor: pointer"
          @click="clearListings"
          src="../../assets/images/close_red.svg"
        />
      </div>
      <div class="divider">•</div>
      <button
        id="change-status-bulk"
        @click="openStatusDropdown"
        style="background-color: black !important; margin-right: 8px"
        class="outlined-small-button"
      >
        <p style="white-space: nowrap" class="filter">
          {{ $t("ChangeStatus") }}
        </p>
        <img src="../../assets/images/chevron-down.svg" class="filter" alt="" />
      </button>
      <GroupOrUserSelect
        emptyValueLabelTranslation="ChangeUser"
        color="invert"
        v-model="chosenUsers"
        :allow-empty="false"
        :allow-only-first-level-data="true"
      />
      <div class="divider">•</div>
      <button
        v-if="selectedListingsList.length === 1"
        @click="duplicateListings"
        style="background-color: black !important; margin-right: 8px"
        class="outlined-small-button"
      >
        <p class="filter">{{ $t("Duplicate") }}</p>
      </button>
      <button
        v-if="selectedListingsList.length > 1"
        @click="duplicateModalOpen"
        id="duplicate-button"
        style="background-color: black !important; margin-right: 8px"
        class="outlined-small-button"
      >
        <p class="filter">{{ $t("Duplicate") }}</p>
      </button>
      <button
        v-if="activeTab === 'listings'"
        @click="deleteModal"
        id="delete-button"
        style="background-color: black !important; margin-right: 8px"
        class="outlined-small-button"
      >
        <p class="filter">{{ $t("Delete") }}</p>
      </button>
      <button
        v-if="activeTab === 'archives'"
        @click="restoreListings"
        style="background-color: black !important; margin-right: 8px"
        class="outlined-small-button"
      >
        <p class="filter">{{ $t("Restore") }}</p>
      </button>
      <button
        v-if="activeTab === 'archives'"
        @click="deletePermanentlyModal"
        style="background-color: black !important; margin-right: 8px"
        class="outlined-small-button"
      >
        <p style="white-space: nowrap" class="filter">
          {{ $t("Delete") }}
        </p>
      </button>
    </div>
    <div id="listing-content">
      <PaginationNumbers
        :key="listingTableUpdater"
        v-if="totalPages > 0"
        class="pagination-numbers"
        :total-pages="totalPages"
        :total="totalListings"
        :per-page="limit"
        :current-page="currentPage"
        @pagechanged="onPageChange"
      />
      <Content
        :listing-table-updater="listingTableUpdater"
        :selected-listings-list="selectedListingsList"
        :key="contentUpdater"
        v-if="listings"
        :total-listings="totalListings"
        :listings="listings"
        :conditionsObj="conditionsObj"
        :statisticsWidgetStatus="statisticsWidgetStatus"
        :selectedBrokerId="selectedBrokerId"
        @selected="selectedListings"
        @clear-filters="clearFiltersFromListings"
        @sortListings="initListings($event)"
        @boardLeadsUpdated="boardLeadsUpdater++"
        @widget-status="changeStatisticsWidgetStatus"
      />

      <PaginationNumbers
        :key="listingTableUpdater + 'updater'"
        v-if="totalPages > 0"
        class="pagination-numbers"
        :total-pages="totalPages"
        :total="totalListings"
        :per-page="limit"
        :current-page="currentPage"
        @pagechanged="onPageChange"
        v-bind:style="
          selectedListingsList.length > 0 ? 'bottom: -100px' : 'bottom: 0px'
        "
      />
      <!--      style="bottom: -100px"-->
    </div>
    <StatusDropdown
      @closed="statusesOpen = false"
      @statusChanged="changeListingStatuses"
      @statusesChanged="changeListingStatuses"
      @open-booking-modal="openBookingDetailsModal"
      v-if="statusesOpen"
      :position="position"
      :listings="selectedListingsList"
      :multi="true"
      :selected-listings-list="selectedListingsList.length"
    />
    <ListingConfirmationModal
      @canceled="deleteModalOpen = false"
      @approved="deleteListings()"
      :count="selectedListingsList.length"
      v-if="deleteModalOpen"
    />
    <ListingConfirmationModal
      @canceled="deletePermanentlyModalOpen = false"
      @approved="deleteListingsPermanently()"
      :count="selectedListingsList.length"
      v-if="deletePermanentlyModalOpen"
    />
    <ListingDuplicationModal
      @canceled="duplicateModal = false"
      @approved="duplicateListings()"
      :count="selectedListingsList.length"
      v-if="duplicateModal"
    />
    <OwnershipConfirmationModal
      @canceled="closePanel()"
      @approved="transferOwnership()"
      :count="selectedListingsList.length"
      v-if="ownershipModal"
    />
    <BookingDetailsModal
      v-if="bookingDetailsModalOpen"
      :bulk-booking="true"
      :listings="selectedListingsList"
      @update-listings="openConfirmationModal"
      @close-modal="bookingDetailsModalOpen = false"
    />
    <StatusConfirmationModal
      @canceled="!statusModalOpen"
      @approved="changeListingsStatuses()"
      v-if="statusModalOpen"
      :count="selectedListingsList.length"
    />
  </div>
</template>

<script>
import Header from "../../components/Listings/Header.vue";
import Content from "../../components/Listings/Content.vue";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import ListingSidepanel from "@/components/Listings/ListingSidepanel.vue";
import BookingDetailsModal from "@/components/Listings/BookingDetailsModal";
import StatusConfirmationModal from "@/components/Listings/StatusConfirmationModal";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import ListingConfirmationModal from "@/components/Listings/ListingConfirmationModal";
import ListingDuplicationModal from "@/components/Listings/ListingDuplicationModal";
import OwnershipConfirmationModal from "@/components/Listings/OwnershipConfirmationModal";
import PaginationNumbers from "@/components/common/PaginationNumbers";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import listingApi from "@/http/listing";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";

export default {
  name: "Listings",
  components: {
    Header,
    Content,
    ClientSidepanel,
    ListingSidepanel,
    BookingDetailsModal,
    StatusConfirmationModal,
    StatusDropdown,
    ListingConfirmationModal,
    ListingDuplicationModal,
    OwnershipConfirmationModal,
    PaginationNumbers,
    GroupOrUserSelect,
  },
  data() {
    return {
      chosenUsers: null,
      bookingDetailsModalOpen: false,
      statusModalOpen: false,
      bulkBookingData: null,
      lastVisitedListingPage: 1,
      fromListingsAdd: false,
      conditionsObj: {},
      totalPages: 0,
      currentPage: 1,
      limit: 50,
      totalListings: 0,
      changedObject: null,
      offersCustomerId: "",
      offersListingId: "",
      statusValues: null,
      dropdownUpdater: 0,
      searchInput: "",
      contactBarOpen: false,
      deleteModalOpen: false,
      deletePermanentlyModalOpen: false,
      duplicateModal: false,
      ownershipModal: false,
      selectedOwnershipItem: null,
      colleagues: [
        {
          name: this.$t("ChangeUser"),
          value: "allBrokers",
          _id: nanoid(16),
        },
      ],
      selectedListingsList: [],
      loaded: false,
      selectedBrokerId: null,
      filtersListOpen: false,
      statusesOpen: false,
      position: {},
      offerListings: null,
      listings: null,
      contentUpdater: 0,
      filterConditions: [],
      savedViewId: null,
      listingTableUpdater: 0,
      lastSort: null,
      boardLeadsUpdater: 0,
      statisticsWidgetStatus: {},
    };
  },
  async created() {
    this.initialize();
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "clientSidepanelStatus",
      "listingSidepanelStatus",
      "modalDeals",
      "user",
      "users",
      "listingsLoaded",
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "viewState",
      "filters",
      "listingsUpdater",
      "activeTab",
    ]),
  },
  methods: {
    ...mapActions([
      "getListings",
      "setClientSidepanel",
      "setListingSidepanel",
      "changeModalStateDeals",
      "setActiveListingsView",
      "setProvisionalFilter",
      "setViewState",
      "changeFilterCondition",
    ]),
    async initialize() {
      await this.setActiveListingsView(this.activeTab);
      await this.changeModalStateDeals(0);

      if (!this.$route.params.id) {
        await this.setListingSidepanel(0);
        await this.setClientSidepanel(0);
      }
      await this.getColleagues();
      await this.totalPagesCounter();

      let listingIdFromParams = this.$route.params.listingId;
      if (this.$route.params.fromListingsAdd) {
        this.fromListingsAdd = true;
      } else {
        this.fromListingsAdd = false;
      }
      if (listingIdFromParams) {
        await this.$router.push({
          name: "ListingsDetail",
          params: { listingId: listingIdFromParams },
        });
        await this.setListingSidepanel();
      }
    },
    openBookingDetailsModal() {
      this.bookingDetailsModalOpen = true;
      this.statusesOpen = false;
    },
    openConfirmationModal(event) {
      this.bookingDetailsModalOpen = false;
      this.bulkBookingData = event;
      this.statusModalOpen = true;
    },
    async changeListingsStatuses() {
      this.statusModalOpen = false;

      let apiData = {
        statusObjects: [],
        activeUntil: null,
      };
      for (const listing of this.selectedListingsList) {
        let statusObject = {
          listingId: listing._id,
          status: "booked",
          bookingComment: this.bulkBookingData.bookingComment,
          bookingType: this.bulkBookingData.bookingType,
          bookerContact: this.bulkBookingData.bookerContact,
          bookedUntil: this.bulkBookingData.bookedUntil,
          statusChangedDate: this.listing?.statusChangedDate,
          lastStatus: listing.status,
        };
        if (listing?.statusChangedDate) {
          statusObject.statusChangedDate = Date.now();
        }
        apiData.statusObjects.push(statusObject);
      }
      const statusesChangedResponse = await axios.post(
        `/api/listing/update-status/multi`,
        apiData
      );
      apiData.activeUntil = statusesChangedResponse.data.expiryDate;

      this.selectedListingsList.forEach((listing) => {
        const bookedListingIndex = this.listings.findIndex(
          (element) => element._id === listing._id
        );
        this.listings[bookedListingIndex].status = "booked";
        this.listings[bookedListingIndex].bookingComment =
          this.bulkBookingData.bookingComment;
        this.listings[bookedListingIndex].bookingType =
          this.bulkBookingData.bookingType;
        this.listings[bookedListingIndex].bookerContact =
          this.bulkBookingData.bookerContact;
        this.listings[bookedListingIndex].bookedUntil =
          this.bulkBookingData.bookedUntil;
      });

      this.selectedListingsList = [];
      this.contentUpdater++;
    },
    changeStatisticsWidgetStatus(status) {
      this.statisticsWidgetStatus = status;
    },
    totalPagesCounter() {
      this.totalPages = Math.ceil(this.totalListings / this.limit);
    },
    async onPageChange(page, notificationId, listingStatus) {
      await this.$router.push({
        name: "Listings",
        query: {
          page: page,
          notificationId: notificationId,
          listingStatus: listingStatus,
        },
      });
      this.currentPage = page;
      window.scroll(0, 0);
    },
    selectBrokerId(brokerId) {
      this.selectedBrokerId = brokerId;
    },
    async initListingsProvisionalFilter() {
      const stateValue = this.viewState["listings"];
      const listingsFilters = this.filters["listings"];
      let savedViewId = this.$route.params.savedViewId;
      this.savedViewId = savedViewId;
      if (
        this.$route.query.notificationId ||
        this.$route.params.notificationId ||
        this.$route.query.notificationId === 0
      ) {
        savedViewId = null;
        this.savedViewId = null;
        await this.setActiveListingsView(this.$route.query.listingStatus);
        this.filterConditions = listingsFilters[0].conditions;
      } else if (savedViewId) {
        this.filterConditions = listingsFilters.find(
          (dropdownItem) => dropdownItem._id === savedViewId
        ).conditions;
      } else if (stateValue) {
        this.filterConditions = this.provisionalFilters["listings"];
        await this.setViewState({
          viewName: "listings",
          value: false,
        });
      } else {
        const defaultFilters = listingsFilters.find(
          (dropdownItem) => dropdownItem.isDefault
        );
        this.filterConditions = defaultFilters.conditions;
      }

      await this.setProvisionalFilter({
        filterName: "listings",
        conditions: this.filterConditions,
      });
    },
    updateListing(event) {
      this.changedObject = event;
      this.listings.forEach((listing, index) => {
        if (listing._id === this.changedObject.listingId) {
          listing.status = this.changedObject.status;
          listing.bookedUntil = this.changedObject.bookedUntil;
          listing.activeUntil = this.changedObject.activeUntil;
          if (this.changedObject.status === "archived") {
            this.listings.splice(index, 1);
          }
          if (this.activeTab === "archives") {
            this.listings.splice(index, 1);
          }
        }
      });
      this.contentUpdater++;
    },
    changeOffersSidepanelStatus(status) {
      this.offersSidepanelOpen = status;
    },
    setOffersAmount(value) {
      this.offersAmount = value;
    },
    openClientFromOffers(value) {
      this.setClientSidepanel();
      this.offersCustomerId = value;
    },
    openListingFromOffers(value) {
      this.setListingSidepanel();
      this.offersListingId = value;
    },
    async getColleagues() {
      const response = await axios.get("/api/users");
      const users = response.data;
      users.forEach((user) => {
        if (user._id === this.user._id) {
          this.colleagues.push(user);
        } else {
          this.colleagues.push(user);
        }
      });
    },
    selectedListings: function (value) {
      this.selectedListingsList = value;
      if (this.selectedListingsList.length > 0) {
        let sidepanelLeads = document.getElementById("sidepanel-leads") ?? "";
        if (sidepanelLeads.className === "panel-wrapper slide-open") {
          this.contactBarOpen = true;
        } else {
          this.contactBarOpen = false;
        }
      }
    },
    filtersOpen(value) {
      this.filtersListOpen = value;
    },
    clearListings() {
      this.selectedListingsList = [];
      this.selectedListings(this.selectedListingsList);
    },
    async changeTab(tab) {
      this.clearListings();
      await this.setActiveListingsView(tab);
      this.initListings();
    },
    openStatusDropdown() {
      let button = document.getElementById("change-status-bulk");
      const rect = button.getBoundingClientRect();
      this.position.right = window.innerWidth - rect.right;
      this.position.top = rect.top + window.scrollY - 50;
      this.statusesOpen = true;
    },
    async changeListingStatuses(event) {
      event.statusObjects.forEach((statusObject) => {
        if (
          statusObject.status === "archived" ||
          this.activeTab === "archives"
        ) {
          this.initListings().then(() => {
            this.listingsLoaded = true;
            this.loaded = true;
          });
        } else {
          this.selectedListingsList.forEach((listing) => {
            if (listing._id === statusObject.listingId) {
              listing.status = statusObject.status;
              listing.bookedUntil = statusObject.bookedUntil;
              listing.activeUntil = event.activeUntil;
            }
          });
        }
      });
      this.selectedListingsList = [];
      this.statusesOpen = false;
      this.contentUpdater++;
      // await this.getListings().then(() => {
      //   this.listingsLoaded = true;
      //   this.loaded = true;
      // });
    },
    async transferOwnership() {
      let brokerId = null;
      if (this.selectedOwnershipItem.value !== "allBrokers") {
        brokerId = this.selectedOwnershipItem._id;
        location.reload();
      } else {
        this.ownershipModal = false;
        return;
      }

      await new Promise((resolve, reject) => {
        for (let listing of this.selectedListingsList) {
          let body = { newOwnerId: brokerId, listingId: listing._id };
          axios
            .post("/api/listing/ownership/change", body)
            .then((response) => console.log(response))
            .catch((e) => {
              console.error(e);
              reject();
            });
        }
        resolve();
      });
      this.statusesOpen = false;
      this.selectedListingsList = [];
      await this.getListings().then(() => {
        this.loaded = true;
      });
    },
    async duplicateListings() {
      let listingIds = [];
      this.selectedListingsList.forEach((listing) => {
        listingIds.push(listing._id);
      });
      await axios.post("/api/listing/duplicate/bulk", { listingIds });
      location.reload();
    },
    deleteModal() {
      this.deleteModalOpen = true;
    },
    deletePermanentlyModal() {
      this.deletePermanentlyModalOpen = true;
    },
    duplicateModalOpen() {
      this.duplicateModal = true;
    },
    statusChangeModalOpen() {
      this.statusesOpen = false;
      if (this.selectedListingsList.length > 1) {
        this.statusModal = true;
      } else {
        this.changeListingStatuses();
      }
    },
    closePanel() {
      this.ownershipModal = false;
      this.dropdownUpdater++;
      this.chosenUsers = null;
    },
    async deleteListings() {
      let listingIds = [];
      this.selectedListingsList.forEach((listing) => {
        listingIds.push(listing._id);
      });
      await axios.post("/api/listings/delete", { listingIds });
      location.reload();
    },
    async restoreListings() {
      let listingIds = [];
      this.selectedListingsList.forEach((listing) => {
        listingIds.push(listing._id);
      });
      await axios.post("/api/listings/restore", { listingIds });
      location.reload();
    },
    async deleteListingsPermanently() {
      let listingIds = [];
      this.selectedListingsList.forEach((listing) => {
        listingIds.push(listing._id);
      });
      await axios.post("/api/listings/delete-permanently", { listingIds });
      location.reload();
    },
    changeSearch(val) {
      this.searchInput = val;
    },
    updateFromSidepanel(statusValues) {
      this.statusValues = statusValues;
    },
    usableListings(value) {
      if (value) {
        this.offerListings = value.filter(
          (listing) =>
            listing.funnels.Offers && listing.funnels.Offers.length > 0
        );
      }
    },
    async initListings(event) {
      const archived = this.activeTab === "archives";
      let response = null;

      if (
        !this.provisionalFilters["listings"].find(
          (condition) => condition.name === "months"
        ).value.length &&
        this.provisionalFilters["listings"].find(
          (condition) => condition.name === "months"
        ).value !== ""
      ) {
        this.provisionalFilters["listings"].find(
          (condition) => condition.name === "months"
        ).value = "";
      }
      if (
        !this.provisionalFilters["listings"].find(
          (condition) => condition.name === "updatedTo"
        )
      ) {
        this.provisionalFilters["listings"].push({
          name: "updatedFrom",
          title: "updatedFrom",
          value: "",
          valueTitle: "",
        }, 
        {
          name: "updatedTo",
          title: "updatedTo",
          value: "",
          valueTitle: "",
        }, 
        { name: "publishedFrom",
          title: "publishedFrom",
          value: "",
          valueTitle: ""},

          {name: "publishedTo",
          title: "publishedTo",
          value: "",
          valueTitle: "",});
      }
      if (
        !this.provisionalFilters["listings"].find(
          (condition) => condition.name === "createdFrom"
        )
      ) {
        this.provisionalFilters["listings"].push({
          name: "createdFrom",
          title: "createdFrom",
          value: "",
          valueTitle: "",
        }, 
        {
          name: "createdTo",
          title: "createdTo",
          value: "",
          valueTitle: "",
        })
      }

      const conditions = this.provisionalFilters.listings;

      this.conditionsObj = conditions.reduce(
        (a, v) => ({ ...a, [v.name]: v.value }),
        {}
      );

      const localStorageConditions = JSON.parse(localStorage.getItem("vuex"));

      let data;

      if (event) {
        this.lastSort = {
          sortField: event.sortField,
          sortOrder: event.sortOrder,
        };
        data = {
          lang: localStorageConditions.a.language,
          page: this.$route.query.page,
          filters: this.conditionsObj,
          limit: this.limit,
          sortField: event.sortField,
          sortOrder: event.sortOrder,
        };
      } else {
        data = {
          lang: localStorageConditions.a.language,
          page: this.$route.query.page,
          filters: this.conditionsObj,
          limit: this.limit,
          sortField: "",
          sortOrder: "",
        };
        if (this.lastSort) {
          data.sortField = this.lastSort.sortField;
          data.sortOrder = this.lastSort.sortOrder;
        }
      }

      if (archived) {
        response = await listingApi.getListingsFilteredArchived(data);
        console.log("Done, state: archived");
      } else {
        response = await listingApi.getListingsFilteredPage(data);
        console.log("Done, state: active");
      }
      this.listings = response.listingsList || response;
      this.totalListings = response.totalListings;
      this.totalPagesCounter();
      this.listingTableUpdater++;
    },
    clearFiltersFromListings() {
      this.$refs.header.clearFilters();
    },
  },
  watch: {
    chosenUsers: async function (value) {
      if (value && value.value) {
        const chosenValue = this.users.find((user) => user._id === value.value);
        this.selectedOwnershipItem = chosenValue;
        this.ownershipModal = true;
      }
    },
    async provisionalFiltersUpdater() {
      console.log("Filters updated");
      if (this.$route.params.fromListingsAdd) {
        this.fromListingsAdd = true;
      } else {
        this.fromListingsAdd = false;
      }
      let listingIdFromParams = this.$route.params.listingId;
      if (listingIdFromParams) {
        await this.$router.push({
          name: "ListingsDetail",
          params: { listingId: listingIdFromParams, fromListingsAdd: true },
        });
        await this.initListings();
        return;
      }
      let pageNumber = 1;
      if (this.$route.query.page) {
        pageNumber = this.$route.query.page;
      }
      if (this.$route.params.previousListingPage) {
        pageNumber = this.$route.params.previousListingPage;
      }
      if (
        this.$route.query.notificationId ||
        this.$route.query.notificationId === 0
      ) {
        await this.setActiveListingsView(this.$route.query.listingStatus);
        this.savedViewId = null;
      }
      await this.$router.push({
        name: "Listings",
        query: {
          page: pageNumber,
          notificationId: this.$route.query.notificationId,
          listingStatus: this.$route.query.listingStatus,
        },
      });
      await this.initListings();
      this.currentPage = pageNumber;
    },
    listingsUpdater: async function () {
      await this.initListings();
      if (this.$route.params.fromListingsAdd) {
        this.fromListingsAdd = true;
      } else {
        this.fromListingsAdd = false;
      }
      let listingIdFromParams = this.$route.params.listingId;
      if (listingIdFromParams) {
        await this.$router.push({
          name: "ListingsDetail",
          params: { listingId: listingIdFromParams },
        });
      }
    },
    "$route.query.page": async function (newVal) {
      if (!newVal) return;
      this.lastVisitedListingPage = this.$route.query.page;
      this.currentPage = this.$route.query.page;

      if (
        this.$route.query.notificationId ||
        this.$route.params.notificationId
      ) {
        this.savedViewId = null;
        await this.setActiveListingsView(this.$route.query.listingStatus);
        await this.initListingsProvisionalFilter();
      } else {
        this.clearListings();
        await this.initListings();
      }
      /*if (this.$route.query.notificationId) {
        this.activeTab = this.$route.query.listingStatus;
        await this.onPageChange(
          this.$route.query.page,
          this.$route.query.notificationId,
          this.$route.query.listingStatus
        );
      }*/
    },
    "$route.params.listingId": async function () {
      if (this.$route.params.fromListingsAdd) {
        this.fromListingsAdd = true;
      } else {
        this.fromListingsAdd = false;
      }

      let listingIdFromParams = this.$route.params.listingId;
      if (listingIdFromParams) {
        await this.$router.push({
          name: "ListingsDetail",
          params: { listingId: listingIdFromParams },
        });
      }
      if (this.$route.params.listingId) {
        this.setListingSidepanel(1);
      }
    },
    /*    "$route.query.notificationId": async function (val) {
      if (!val) return;
      await this.onPageChange(this.$route.query.page, val);
    },*/
    async listingsLoaded(val) {
      if (val) {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss">
body {
  overflow-x: auto;
}

#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}

#listing-content {
  padding-bottom: 72px;
  //  //overflow-x: scroll;
}

.slide-animation-open {
  margin-left: 256px !important;
}

.slide-animation-close {
  margin-left: 0 !important;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7px;
  margin-left: 16px;
  margin-right: 16px;
}

.filter {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
    brightness(103%) contrast(102%);
}

.selected-listings-display {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 91px;
  max-width: 91px;
  height: 24px;
  white-space: nowrap;
  background-color: white;
  border: 1px solid white;
  color: black;
}

.selected-listings-header {
  display: flex;
  z-index: 2;
  height: 64px;
  width: 100%;
  background-color: black;
  padding-left: 104px;
  color: white;
  align-items: center;
}

.dropdown-ownership {
  .dropbtn {
    background: transparent !important;

    p {
      color: white !important;
    }
  }

  .dropbtn-hover {
    &:hover,
    &:focus {
      cursor: pointer !important;

      background-color: transparent !important;
    }
  }

  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
      brightness(103%) contrast(102%) !important;
  }
}

.pagination-numbers {
  margin-left: 679px;
  width: 300px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  height: 64px;
  color: black;
}
</style>
